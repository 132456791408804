import { connect } from "react-redux";

function Layout({children}){
    return(

        <div className="bg-slate-900">
            {children}
        </div>
    )
}
const mapStateToProps = state =>({

})

export default connect(mapStateToProps,{

}) (Layout)