import icono1 from 'assets/img/icono1.png'

function Hero(){
    return(
     <div className='flex flex-col justify-center items-center p-10 h-screen'>
        <div><img src ={icono1} width={500} /></div>
        <div><p className='text-center text-slate-50 font-bold text-xl mb-5  sm:text-3xl '>Latul studio</p></div>
        <div><p className='text-center text-slate-50 font-extrabold text-xl  mb-10 sm:text-4xl'>Sitio en contrucción</p></div>
        <div><p className='text-center text-slate-50 font-thin text-xl sm:text-2xl'>Estamos trabajando para mejorar tu experiencia</p></div>
     </div>
    )

}export default Hero