import Hero from "components/home/Hero"
import Layout from "hocs/layouts/Layout"

function Home(){
    return(
        <Layout>
            
           <Hero/>
        </Layout>
        
    )

}
export default Home